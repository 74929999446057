html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}

* {
  box-sizing: border-box;
}


a {
  text-decoration: none;
}

.nav-link-item {
  display: flex;
  flex-grow: 1;
  text-decoration: none;
}

.dx-stage-empty-image {
  opacity: 0.3;
  filter: blur(4px);
}

.custom-tooltip-wrapper::before {
  content: '';
  position: absolute;
  background: transparent;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #757575;
}
